import React from 'react';
import './Message.css';

function Message({message}) {

  const messageLink = `/message/${message.id}`;

  return (
    <div className='message'>
      <div className='message_info'>
        <a href={messageLink}>
          <p><strong>Name:</strong> {message.name}</p>
          <p><strong>Email:</strong> {message.email}</p>
          <p><strong>Phone:</strong> {message.phone}</p>
          <p><strong>Message:</strong> {message.data}</p>
        </a>
      </div>
    </div>
  )
}

export default Message