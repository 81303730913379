import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Messages from './Messages';
import './ShowMessages.css';

function ShowMessages() {

  const [messages, setMessages] = useState([]);

  useEffect(() => {

    const fetchMessages = async() => {
        const res = await axios.get('/messages.json')
        .then(res => {
            const messageArray = [];
            for(let key in res.data){
                messageArray.unshift({
                    ...res.data[key],
                    id: key
                })
            }
            setMessages(messageArray);
        })
    };
    fetchMessages();
  }, []);

  console.log(messages)

  return (
    <div className='messages'>
      <h1>Messages</h1>
      <div className='displayMessages'>
        <Messages messages={messages} />
      </div>
      
    </div>
  )
}

export default ShowMessages