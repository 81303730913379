import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './Footer.css';

function Footer() {
  return (
    <>
      <div className='footer'>
        <div className='socials'>
          <a href='https://github.com/RandyMac22'>
            <GitHubIcon />
          </a>
          <a href='https://www.linkedin.com/in/randy-mcmillen-jr/'>
            <LinkedInIcon />
          </a>
        </div>
        <p>© 2022 Randy McMillen Jr.  All Rights Reserved</p>
      </div>
    </>
    
  )
}

export default Footer