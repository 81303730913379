import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './Nav.css';

function LoggedInNav() {
  const [show, setShow] = useState(false);
  
  const showDropdown = (e) => {
    setShow(!show);
  };
  
  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <div className='nav'>
      <div className='navBar'>
        <a href='/'>
          Home
        </a>
        <Dropdown
          className='dropdown'
          onMouseLeave={() => hideDropdown}
          onMouseOver={() => showDropdown}
        >
          <Dropdown.Toggle
            className='main-style'
            id='dropdown-basic'
          >
            Projects
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu' show = {showDropdown}>
            <Dropdown.Item href='/projects/scheduling-app'>
              Scheduling App
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/uniEvent'>
              UniEvent
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/kingWiki'>
              KingWiki
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/atlas'>
              Atlas Health Blog
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/amazon-clone'>
              Amazon Clone
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/baseball-card-collection'>
              Baseball Card Collection
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/busch-birds'>
              Busch Birds Blog
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a href='/messages'>
          Messages
        </a>
        <a href='/logout'>
          Logout
        </a>
      </div>
      
    </div>
  )
}

function LoggedOutNav () {
  const [show, setShow] = useState(false);
  
  const showDropdown = (e) => {
    setShow(!show);
  };
  
  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <div className='nav'>
      <div className='navBar'>
        <a href='/'>
          Home
        </a>
        <Dropdown
          className='dropdown'
          onMouseLeave={() => hideDropdown}
          onMouseOver={() => showDropdown}
        >
          <Dropdown.Toggle
            className='main-style'
            id='dropdown-basic'
          >
            Projects
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu' show = {showDropdown}>
            <Dropdown.Item href='/projects/scheduling-app'>
              Scheduling App
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/uniEvent'>
              UniEvent
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/kingWiki'>
              KingWiki
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/atlas'>
              Atlas Health Blog
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/amazon-clone'>
              Amazon Clone
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/baseball-card-collection'>
              Baseball Card Collection
            </Dropdown.Item>
            <br />
            <Dropdown.Item href='/projects/busch-birds'>
              Busch Birds Blog
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <a href='/about'>
          About
        </a>
        <a href='/contact'>
          Contact Us
        </a>
      </div>
      
    </div>
  )
}

function Header() {
  let cookies = document.cookie.split(";")
    .map(cookie=> cookie.split('='))
    .reduce((accumulator, [key, value]) => ({...accumulator, [key.trim()]: decodeURIComponent(value)}),
  {});

  if (cookies.loggedIn === 'true') {
    return <LoggedInNav />
  } else {
    return <LoggedOutNav />
  }

}

export default Header