import axios from 'axios';
import React, { useEffect, useState } from 'react';


function SingleMessage() {

  const path = window.location.pathname.split('/')[2];
  const [message, setMessage] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    const getMessage = () => {
      axios.get(`/messages/${path}.json`)
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(data);
        setMessage(data);
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone);
        setData(data.data);
      })
    };
    getMessage();
  }, [path]);

  return (
    <div className='singleMessage'>
      <div className='singleMessageWrapper'>
        <h1>{name}</h1>
        <p>{email}</p>
        <p>{phone}</p>
        <p>{data}</p>
      </div>
    </div>
  )
}

export default SingleMessage