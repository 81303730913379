import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNbKvc-1ROBkiyIooFuRJX7l5GihphSoU",
  authDomain: "mcmillendevelopment.firebaseapp.com",
  databaseURL: "https://mcmillendevelopment-default-rtdb.firebaseio.com",
  projectId: "mcmillendevelopment",
  storageBucket: "mcmillendevelopment.appspot.com",
  messagingSenderId: "640277709925",
  appId: "1:640277709925:web:41e25bb66d2470dcfd328c"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.database();

const auth = firebase.auth();

export { db, auth };