import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import cookie from "react-cookies";


function Logout() {
    const location = useNavigate();
    auth
    .signOut()
    .then(data => {
        cookie.remove("user");
        cookie.remove("loggedIn");
        location("/");
    })
}

export default Logout