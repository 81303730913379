import Github from "./img/GitHub-Mark.png";
import BuschBirds from './img/BuschBirds-main.png';
import Login from './img/BuschBirds-login.png';
import Register from './img/BuschBirds-register.png';
import WriteArticle from './img/BuschBirds-writeArticle.png';

export default function BaseballCardCollection() {
    return(
        <>
            <div className='details'>
                <h1>Baseball Card Collection</h1>
                <div className="detailContainer">
                    <p>
                      The Busch Birds blog page is a personal project.  I have been a lifelong St. Louis Cardinals fan and wanted to write about them for a while now.  I've started to write some preliminary blogs and may be posting to the web soon.  
                    </p>
                    <img src={BuschBirds} alt="Busch Birds Landing page" />
                    <p>
                        The login page uses authorization from Firebase.
                    </p>
                    <img src={Login} alt="login" />
                    <p>
                        The registration page is set up to use auth from Firebase
                    </p>
                    <img src={Register} alt="register" />
                    <p>
                        The write article page is only accessible by authorized writers.  It requires input from you and a link to a picture (if you want one included) from the internet.  It takes the body of the article in a rich text editor that allows you to format your writing if you wish.  
                    </p>
                    <img src={WriteArticle} alt="Write Article page" />
                </div>
                <a href="https://github.com/RandyMac22/baseball-card-collection">
                    <img className="github" src={Github} alt="tinyGithub"/>
                </a>
            </div>
        </>
    )
}