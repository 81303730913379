import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Header from './Components/Nav';
import Login from './Components/Login';
import About from './Components/About';
import Contact from './Components/Contact';
import ShowMessages from './Components/ShowMessages';
import Logout from './Components/Logout';
import Single from './Components/Single';
import SchedulingApp from './Components/SchedulingApp';
import UniEvent from './Components/UniEvent';
import KingUniWiki from './Components/KingWiki';
import AtlasHealth from './Components/Atlas';
import Amazonclone from './Components/AmazonClone';
import BaseballCardCollection from './Components/BaseballCardCollection';
import BuschBirds from './Components/BuschBirds';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route 
            path='/' element={
              <React.Fragment>
                <Header />
                <Home />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/login' element={
              <React.Fragment>
                <Header />
                <Login />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/logout' element={
              <Logout />
            }
          />
          <Route 
            path='/about' element={
              <React.Fragment>
                <Header />
                <About />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path='/contact' element={
              <React.Fragment>
                <Header />
                <Contact />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/messages' element={
              <React.Fragment>
                <Header />
                <ShowMessages />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path='/message/:id' element = {
              <React.Fragment>
                <Header />
                <Single />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path='/projects/scheduling-app' element = {
              <React.Fragment>
                <Header />
                <SchedulingApp />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/projects/uniEvent' element={
              <React.Fragment>
                <Header />
                <UniEvent />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/projects/kingWiki' element={
              <React.Fragment>
                <Header />
                <KingUniWiki />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/projects/atlas' element={
              <React.Fragment>
                <Header />
                <AtlasHealth />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path='/projects/amazon-clone' element={
              <React.Fragment>
                <Header />
                <Amazonclone />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path='/projects/baseball-card-collection' element={
              <React.Fragment>
                <Header />
                <BaseballCardCollection />
                <Footer />
              </React.Fragment>
            }
          />
          <Route 
            path='/projects/busch-birds' element={
              <React.Fragment>
                <Header />
                <BuschBirds />
                <Footer />
              </React.Fragment>
            }
          />
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;
