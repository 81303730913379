import React, { useState, useEffect } from 'react';
import CKEditor from './CKEditor';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Contact.css';

function Contact() {

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  let location = useNavigate()

  function submitMessage() {
    let info = {
      name,
      email,
      phone, 
      data
    }
    //Post the message to Firebase
    axios.post('/messages.json', info)
      .then(res => {
        if (res.status === 200) {
          location('/');
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <div className='contact'>
      <h1>Contact Us</h1>
      <input type='text' value={name} placeholder='Name' onChange={e => setName(e.target.value)} />
      <br />
      <br />
      <input type='email' value={email} placeholder='Email' onChange={e => setEmail(e.target.value)} />
      <br />
      <br />
      <input type='phone' value={phone} placeholder='Phone Number' onChange={e => setPhone(e.target.value)} />
      <br />
      <br />
      <CKEditor
        id="editor"
        name="description"
        onChange={(data) => {
        setData(data);
        }}
        editorLoaded={editorLoaded}
      />
      <br />
      <br />
      <button id='contactSubmitBtn' onClick={submitMessage}>Send Message</button>
    </div>
  )
}

export default Contact