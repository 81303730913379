import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SingleMessage from './SingleMessage';
import './Single.css';

function Single(props) {

  const [messages, setMessages] = useState();

  useEffect(() => {
    const fetchMessages = async () => {
      const res = await axios.get('/articles.json');
      setMessages(res.data)
    }
    fetchMessages();
  }, []);

  return (
    <div className='single'>
      <SingleMessage 
        key={props.id}
        name={props.name}
        email={props.email}
        phone={props.phone}
        data={props.data}
      />
    </div>
  )
}

export default Single