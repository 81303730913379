import React, { useState } from 'react';
import './Login.css';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Login() {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cookie, setCookie] = useCookies();
  const history = useNavigate();

  const signIn = e => {
    e.preventDefault();
    // Firebase login
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        console.log(auth);
        if (auth) {
          setCookie('user', auth.user.email, {path: '/'});
          setCookie('loggedIn', 'true', {path: '/'});
        }
        history('/');
      })
      .catch(err => alert(err.message));
  }

  return (
    <div className='login'>
      <h1>Login</h1>
      <input type="email" value={email} placeholder='Email' onChange={e => setEmail(e.target.value)} />
      <br />
      <br />
      <input type='password' value={password} placeholder='Password' onChange={e => setPassword(e.target.value)} />
      <br />
      <br />
      <button onClick={signIn}>Sign In</button>
    </div>
  )
}

export default Login