import React from 'react';
import './Home.css';
import mcmillenDev from './img/McMillenDevelopment.jpg';

function Home() {
  return (
    <div className='home'>
      <img src={mcmillenDev} alt='' />
    </div>
  )
}

export default Home